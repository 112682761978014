
import Intro from '~/components/organisms/home/Intro.vue'
import Wifi from '~/components/organisms/home/Wifi.vue'
import Features from '~/components/organisms/home/Features.vue'
import PlanAndFee from '~/components/organisms/home/PlanAndFee.vue'
import About from '~/components/organisms/home/About.vue'
import Feedback from '~/components/organisms/home/Feedback.vue'
import Qa from '~/components/organisms/home/Qa.vue'
import HorieNoSaikyoWifi from '~/components/organisms/home/HorieNoSaikyoWifi.vue'
import CorporatePlan from '~/components/organisms/home/CorporatePlan.vue'
import Coming from '~/components/organisms/home/Coming.vue'
import Recruiting from '~/components/organisms/home/Recruiting.vue'
import BoxHighlightBtns from '~/components/organisms/home/BoxHighlightBtns.vue'

export default {
  layout: 'user',

  components: {
    Intro,
    Wifi,
    Features,
    PlanAndFee,
    About,
    Feedback,
    Qa,
    HorieNoSaikyoWifi,
    CorporatePlan,
    Coming,
    Recruiting,
    BoxHighlightBtns
  },

  data() {
    return {
      btnsOffset: null,
      isSettedBtnsOffset: false
    }
  },

  beforeMount() {
    window.removeEventListener('scroll', this.onScroll)
  },

  mounted() {
    window.addEventListener('scroll', this.onScroll)

    const footer = document.getElementsByClassName('main-user-footer')
    footer[0].style.paddingBottom = '20rem'
  },

  methods: {
    scrollToTop() {
      window.scrollTo(0, 0)
    },

    onScroll(e) {
      if (!this.isSettedBtnsOffset) {
        const btnsHome = document.getElementById('con-box-highlight-btns-home')

        if (btnsHome) {
          this.btnsOffset = btnsHome.getBoundingClientRect().top
          this.isSettedBtnsOffset = true
        }
      }

      const elm = document.getElementById('con-box-highlight-btns-fixed')
      let offset = 500

      if (!isNaN(this.btnsOffset)) {
        offset = this.btnsOffset
      }

      if (window.top.scrollY > offset) {
        elm?.classList.add('active')
      } else {
        elm?.classList.remove('active')
      }

      if (window.top.scrollY === 0) {
        elm?.classList.remove('active')
      }
    }
  }
}
